@import "_variables";
html{
  margin-top: 0!important;
  font-size: 100%;
  @media(max-width: 767px){
    font-size: 90%;
  }
  overflow-x: hidden;
}
body{
    font-family: $global_font;
    overflow-x: hidden;
}
h1,h2,h3,h4{
  font-family: $header_font;
  font-weight: 700!important;
}
.pl0{
  padding-left: 0;
}
.pr0{
  padding-right: 0;
}

.dark{
  .section-title-basic{
    h2{
      color:$body_text_color;
    }
  }
}

.content-columns .dark h3{
  color: $primary_color;
}

.component .btn-lg span{
  padding: 15px;
  font-size: 1.5rem;
}



.page-title{
  background-color: #f7f8f9;
  h1{
    font-size: 2.65rem;
    color: $body_text_color;
    margin: 20px 0;
  }
  h2{
    font-size: 1.4rem;
  }
  /*box-shadow: 0px 0px 30px #999;
  position: relative;
  height: 175px;
  background-size: cover;
  background-position: center center;
  .tag{
    h1{
      font-size: 3rem;
      font-weight: 400;
    }
  }
  &.light{
    .tag{
      h1, h2, h3{
        text-shadow: 0px 0px 15px rgba(0,0,0,.8);
      }

    }
  }*/

}

.close-service-content{
  @media(max-width: 767px){
    padding: 5px 8px;
  }
}
.single-service, .service-content-container{
  .service-title{
    background-color: #f7f8f9;
    .title-overlay{
      background: -moz-linear-gradient(top,#fff 0,rgba(229,229,229,0) 70%);
      /* background: -webkit-linear-gradient(top,#fff 0,rgba(229,229,229,0) 70%); */
      background: linear-gradient(to bottom,#fff 0,rgba(229,229,229,0) 300%);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

      h1{
        font-size: 2.65rem;
        color: $body_text_color;
        margin: 20px 0;
      }
      h2{
        font-size: 1.4rem;
      }

  }
  .sub-head{
    width: 60%;
    margin: auto;
    @media(max-width: 991px){
      width: 100%;
    }
    h2{
      color: $body_text_color;
      font-size: 1.9rem;
      line-height: 2.5rem;
      font-weight: 500!important;
      text-align: center;
    }
  }
}
.service-content-container{
  .service-title{
    h2{
      font-size: 2.65rem;
      color: #202020;
      margin: 20px 0;
    }
  }
}



.bg-top-center{
  background-position: top center!important;
}
.bg-bottom-center{
  background-position: bottom center!important;
}

p{
  font-size: 1.1rem;
}
h2{
  font-size: 2.4rem;
}
h3{
  font-size: 2rem;
}

.btn{
  padding: 5px 25px;
  border-radius: 4px;
  border-color: $primary_color!important;
  box-shadow: none!important;
  span{
    font-size: 1rem!important;
    font-weight: 700!important;
  }
    transition: all ease-in-out .4s;

}
.btn{
  /*font-weight: bold;
  border-radius: 6px;
  padding: 10px 25px;
  text-transform: uppercase;*/

  /*&.btn-white-on-primary{
    background-image: linear-gradient(#225a17, #327126);
    z-index: 100;
    border-width: 0;
    position: relative;

    &:before {
      border-radius: inherit;
      border-width: 0;
       background-image: linear-gradient(#225a17, #225a17);
       content: '';
       display: block;
       height: 100%;
       position: absolute;
       top: 0; left: 0;
       opacity: 0;
       width: 100%;
       z-index: -100;
       transition: opacity 0.45s;
    }

    &:hover {
      &:before {
        opacity: 1;
      }
    }
  }*/




}

.full-screen-billboard{
  /*box-shadow: 0px 0px 30px #999;
  border-bottom: 5px solid rgba(255,255,255,.5);*/
  .content-container{
    background:rgba(255,255,255,.6);
    padding: 25px;
    border-radius: 2px;
  }
  .action-buttons{
    margin-top: 1rem!important;
    .btn{
      padding: 8px 15px;
      span{
        font-size: 1rem!important;
      }
    }
  }
  h2{
    margin: 0;
    font-weight: 600;
    font-size: 2.4rem;
    color: #000;
    font-family: $global_font!important;
  }
  h3{
    margin-top: 0;
    margin-bottom: 0;
    font-size: 2.2rem;
    font-weight: 400!important;
    font-family: $global_font!important;
  }
  &.light{
    h2, h3{
      text-shadow: 0px 0px 15px rgba(0,0,0,.8);
    }
  }
  &.home-page-billboard{
    @media(max-width: 768px){
      padding-top: 15vh!important;
    }
    .content-container{
      @media(max-width: 768px){
        display: none;
      }
    }
  }
}
.mobile-billboard-content{
  padding-bottom: 35px;
  border-bottom: 2px #499b94 solid;
  h2{
    color: $body_text_color;
  }
  h3{
    margin-top: 10px;
    color: #70788C;
    font-family: $global_font;
    font-weight: 400!important;
  }
  .action-buttons{
    width: 100%;
    .btn{
      width: 100%;
    }
  }
}
.steps {
  margin-bottom: 30px;
  .step-number{
    background-color: #245d19;
    color: white;
    font-size: 5rem;
    font-weight: bold;
    border: 3px #FFF solid;
    border-radius: 50%;
    text-align: center;
    display: block;
    margin: 0px auto;
    height: 145px;
    width: 145px;
    line-height: 1.7em;
    box-shadow: 0px 0px 15px #999;
    h2{
      margin: 0px;
    }
  }
}
.home{
  .service-blocks{
    .service-block{
      text-align: left!important;
      .description{
        display: block!important;
        margin: 10px 0;
      }
    }
  }
}
.service-blocks-container{
  background-color: #ffffff;
  padding: 1rem 0px;
  .service-blocks{
    .service-block{
      text-align: center;
      margin-bottom: 25px;
      background-color: #FFFFFF;
      -webkit-box-shadow: 0px 0px 1px 1px rgba(161,161,161,0.2);
      -moz-box-shadow: 0px 0px 1px 1px rgba(161,161,161,0.2);
      box-shadow: 0px 0px 1px 1px rgba(161,161,161,0.2);
      transition: all .25s;
      &:hover{
        -webkit-box-shadow: 0px 0px 4px 4px rgba(161,161,161,0.4);
        -moz-box-shadow: 0px 0px 4px 4px rgba(161,161,161,0.4);
        box-shadow: 0px 0px 4px 4px rgba(161,161,161,0.4);
        transition: all .25s;
      }
      .content{
        background-color: #FFFFFF;
        padding: 25px;
        .icon-container{
          text-align: center;
          margin-bottom: 15px;
          .icon{
            max-width: 40px;
            width: 40px;
            margin: auto;
          }
        }
      }
      @media(max-width: 767px){
        margin-bottom: 15px;
      }
      h3{
        margin-top:0;
        margin-bottom: 0;
        font-size: 1.4rem;
        color: $body_text_color;
      }
      a:hover{
        text-decoration: none;
      }
      .image-container{
        position: relative;
        transition: all .25s;
        border-top:5px $primary_color solid;

        /*&:hover{
          opacity: .9;
          box-shadow: 0px 0px 7px #999;
        }*/
        .title-overlay{
          position: absolute;
          width: 100%;
          bottom: 0;
          color: #fff;
          background: rgba(0,0,0,.35);
          padding: 10px 10px;
          font-weight: 700;
          font-size: 1.7rem;
          border-radius: 0px 0px 5px 5px;
          &:hover{
            opacity: .9;
          }
        }
      }
      p.description{
        margin: 10px 0;
      }
    }
  }
}


.testimonial{
  background-image: url('/wp-content/themes/aura-theme/img/testimonial-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  p{
    color: $body_text_color;
    font-size: 1.1rem;
    font-weight: 400;
    @media(max-width: 767px){
      font-size: .9rem;
    }
  }
  .half-width-content-image-bg{
    .content-container{
      padding: 25px!important;
      background-color: rgba(255,255,255,.7)!important;
      border:none;
      box-shadow: none;
      h2{
        display: none;
      }
      h3{
        color: $body_text_color;
        font-size: 1.2rem;
        font-weight: 400!important;
      }
    }

  }
}
.testimonials{

  .carousel{
    .carousel-inner{
      background-image: url('../img/testimony-bg.jpg');
      background-repeat: no-repeat;
      background-size: cover;
      .item{
        min-height: 500px;
        @media(max-width: 767px){
          min-height: 100vh;
        }
        padding:35px 0!important;
        .carousel-caption{
          top:0%!important;
          display: inline-block!important;
          vertical-align: middle!important;
          @media(max-width: 767px){
            top:10%;
          }
          width: 70%;
          left: 15%;
          right: 15%;
          .text{
            font-size:1.5rem;
            position: relative;
            top: 50%;
            font-weight: 600;
            transform: translateY(-50%);
            @media(max-width: 767px){
              font-size: 1.2rem;
            }
          }
        }
      }
    }
  }
}

.half-width-content-image-bg{
    min-height: 40vh!important;
  .content-container{
    @media(max-width:767px){
      background-color: #FFF;
    }
    @media(min-width: 768px){
      background-color: transparent;
      padding:40px 40px 40px 40px!important;
    }
    h2{
      font-size: 2rem;
      font-weight: 600;
      color: $body_text_color;
    }
    h3{
      font-size: 1.5rem;
    }
  }
}

.gallery-item{
  position: relative;
  margin-bottom: 30px;
  a{

    img {
      opacity: 1;
      display: block;
      width: 100%;
      height: auto;
      transition: .5s ease;
      backface-visibility: hidden;
    }

    .image-overlay {
      transition: .5s ease;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      text-align: center;
      background:rgba(0,0,0,0.5);
      color: #FFFFFF;
      .text{
        display: inline-block;
        vertical-align: middle;
        padding: 10px 15px;
        font-size: 1.6rem;
      }
    }

    &:hover img {
      opacity: 0.3!important;
    }

    &:hover .image-overlay {
      opacity: 1!important;
    }
  }
}

.cta{
  background-position: center center!important;
  background-repeat: no-repeat!important;
  background-size: cover!important;
  .content-container{
    margin-top:75px;
    margin-bottom: 75px;
    background-color: rgba(255,255,255,.7)!important;
    padding: 25px;
    h3,p{
      text-align: center;
      color: $body_text_color;
    }
  }
}
.footer{
  background-color: #FFFFFF;
  padding: 25px 0px;
  box-shadow: inset 0 15px 20px -10px #CCC;
  border-top: 1px solid #FFF;
  p,a{
    font-weight: 500;
    font-size: .9rem;
  }
  .areas-served{
    border-top: 1px solid #eee;
    padding: 15px 0;
    margin-top: 5px;
    @media(max-width: 767px){
      border-bottom: 1px solid #eee;
      margin-bottom: 0px!important;
      padding-left: 0!important;
      text-align: center;
    }
  }

  ul.footer-nav{
    @media(max-width: 767px){
      margin-bottom: 0px!important;
      padding-left: 0!important;
      text-align: center;
    }
    list-style-type: none;
    li{
      a{
        color: $body_text_color
      }
    }
  }
  .logo-container{
    @media (max-width: 767px){
      text-align: center;
    }
  }
  .phone-container{
    text-align: center;
  }

  .logo{
    height: 65px;
    display: inline-block;
  }

  .phone{
    color: $body_text_color;
  }


  .address {
    margin: 0;
    padding: 0;
    line-height: 1.4em;
    text-align: left;
    @media (max-width: 767px){
      text-align: center;
    }
  }
}

.social-icons a i{
  font-size: 2.6em;
  line-height: 1.3em;
}
