@import "./variables";

.subnav-container{
  width: 100%;
  background-color: #FFF;
  float:left;
}
.subnav{
  list-style-type: none;
}
